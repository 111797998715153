<script setup
        lang="ts">
// nothing here
</script>

<template>
    <div class="footer-logo--wrapper">
        <a class="footer-logo--link"
           href="/">
            reshepe
        </a>
        <div class="bottom-logo--copyright">
            <iframe src="https://status.reshepe.dev/badge?theme=dark"
                    width="250"
                    height="30"
                    frameborder="0"
                    scrolling="no" />
            <div class="footer-logo--copyright">
                © 2023 - {{ new Date().getFullYear() }} reshepe
            </div>
        </div>
    </div>
</template>

<style lang="scss"
       scoped>
.footer-logo--wrapper {
    --footer-logo-link-color:       var(--reshepe-color-white-700);
    --footer-logo-copyright-color:  var(--reshepe-color-white-700);

    // hover
    --footer-logo-link-color-hover: var(--reshepe-color-white-600);

    display:                        flex;
    flex-direction:                 column;
    align-items:                    flex-start;
    justify-content:                space-between;
    width:                          100%;

    .footer-logo--link {
        margin-top:      1.2rem;
        color:           var(--footer-logo-link-color);
        font-size:       2.4rem;
        font-family:     var(--reshepe-font-alt-thin);
        text-decoration: none;
        cursor:          pointer;
        user-select:     none;
        transition:      all 0.2s ease-in-out;

        &:hover {
            color: var(--footer-logo-link-color-hover);
        }
    }

    .footer-logo--copyright {
        margin-top:    32px;
        margin-bottom: 32px;
        color:         var(--footer-logo-copyright-color);
        font-size:     0.85rem;
    }
}
</style>
