<script setup
        lang="ts">
import LinkInternal                  from '../../link/internal/link-internal.vue';
import LinkExternal                  from '../../link/external/link-external.vue';
import ThemeToggle                   from '../../theme-toggle/theme-toggle.vue';
import { type FooterSectionSection } from './footer-section.types';

withDefaults(defineProps<{
    section: FooterSectionSection;
    last?: boolean;
}>(), {
    last: false,
});
</script>

<template>
    <section class="footer-section--wrapper">
        <div class="footer-section--block">
            <div class="footer-section--block__name">
                <h4>{{ section.name }}</h4>
            </div>
            <div class="footer-section--block__links">
                <span v-for="link in section.links"
                      :key="link.text"
                      class="footer-section--block__link">
                    <LinkInternal v-if="!link.external"
                                  class="internal-link"
                                  :text="link.text"
                                  :url="link.url"
                                  astro />
                    <LinkExternal v-else
                                  class="external-link"
                                  :text="link.text"
                                  :url="link.url"
                                  astro />
                </span>
            </div>
        </div>
        <ThemeToggle v-if="last" />
    </section>
</template>

<style lang="scss"
       scoped>
.footer-section--wrapper {
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    width:          100%;

    .footer-section--block {
        margin-bottom: 32px;

        .footer-section--block__name {
            color: var(--reshepe-color-white-700);
        }

        .footer-section--block__links {
            display:        flex;
            flex-direction: column;
            margin-top:     10px;

            :deep(.external-link) {
                --link-external-color:             var(--reshepe-color-primary-700);
                --link-external-icon-color:        var(--reshepe-color-primary-700);
                --link-external-hover-color:       var(--reshepe-color-primary-500);
                --link-external-hover-icon-color:  var(--reshepe-color-primary-500);
                --link-external-active-color:      var(--reshepe-color-primary-500);
                --link-external-active-icon-color: var(--reshepe-color-primary-500);
            }

            :deep(.internal-link) {
                --link-internal-color:             var(--reshepe-color-primary-700);
                --link-internal-icon-color:        var(--reshepe-color-primary-700);
                --link-internal-hover-color:       var(--reshepe-color-primary-500);
                --link-internal-hover-icon-color:  var(--reshepe-color-primary-500);
                --link-internal-active-color:      var(--reshepe-color-primary-500);
                --link-internal-active-icon-color: var(--reshepe-color-primary-500);
            }

            .footer-section--block__link {
                margin-bottom: 8px;
            }
        }
    }

}
</style>
