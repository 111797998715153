<script setup
        lang="ts">
import { type FooterSectionSection } from './section/footer-section.types';
import FooterSection                 from './section/footer-section.vue';
import FooterLogo                    from './logo/footer-logo.vue';

const sections: FooterSectionSection[] = [
    {
        name:  'product',
        links: [
            {
                text:     'features',
                url:      '/features',
                external: false,
            },
            {
                text:     'pricing',
                url:      '/pricing',
                external: false,
            },
        ],
    },
    {
        name:  'blog',
        links: [
            {
                text:     'changelog',
                url:      'https://reshepe.featurebase.app/',
                external: true,
            },
        ],
    },
    {
        name:  'resources',
        links: [
            {
                text:     'contact us',
                url:      '/contact',
                external: false,
            },
            {
                text:     'service status',
                url:      'https://status.reshepe.dev/',
                external: true,
            },
            {
                text:     'cookies settings',
                url:      '/cookies/settings',
                external: false,
            },
            {
                text:     'terms and conditions',
                url:      '/terms',
                external: false,
            },
        ],
    },
];
</script>

<template>
    <footer class="footer--wrapper">
        <div class="footer--sections">
            <FooterLogo />
            <FooterSection v-for="(section, index) in sections"
                           :key="section.name"
                           :section="section"
                           :last="index === sections.length - 1" />
        </div>
    </footer>
</template>

<style lang="scss"
       scoped>
.footer--wrapper {
    display:         flex;
    justify-content: center;
    width:           100%;
    margin-top:      100px;
    background:      var(--reshepe-color-secondary-700);
    clip-path:       polygon(0 100px, 100% 0, 100% 100%, 0% 100%);

    .footer--sections {
        display:         flex;
        flex-direction:  column;
        justify-content: space-between;
        width:           100%;
        max-width:       75rem;
        padding:         140px 16px 16px;

        @include tablet {
            flex-direction: row;
        }
    }
}
</style>
