<script setup
        lang="ts">
import { useThemeStore } from '@reshepe/stores';

const themeStore = useThemeStore();
</script>

<template>
    <button class="theme-toggle"
            :class="themeStore.dark ? 'theme-toggle--toggled' : 'theme-toggle--untoggled'"
            type="button"
            aria-label="Toggle theme"
            @click="themeStore.toggle()">
        <svg xmlns="http://www.w3.org/2000/svg"
             aria-hidden="true"
             width="1em"
             height="1em"
             fill="currentColor"
             stroke-linecap="round"
             class="theme-toggle__classic"
             viewBox="0 0 32 32">
            <clipPath id="theme-toggle__classic__cutout">
                <path d="M0-5h30a1 1 0 0 0 9 13v24H0Z" />
            </clipPath>
            <g clip-path="url(#theme-toggle__classic__cutout)">
                <circle cx="16"
                        cy="16"
                        r="9.34" />
                <g stroke="currentColor"
                   stroke-width="1.5">
                    <path d="M16 5.5v-4" />
                    <path d="M16 30.5v-4" />
                    <path d="M1.5 16h4" />
                    <path d="M26.5 16h4" />
                    <path d="m23.4 8.6 2.8-2.8" />
                    <path d="m5.7 26.3 2.9-2.9" />
                    <path d="m5.8 5.8 2.8 2.8" />
                    <path d="m23.4 23.4 2.9 2.9" />
                </g>
            </g>
        </svg>
    </button>
</template>

<style lang="scss"
       scoped>
.theme-toggle {
    --theme-toggle-classic-duration: 500ms;
    --theme-toggle-color:            var(--reshepe-color-white-700);

    border:                          none;
    background:                      none;
    cursor:                          pointer;

    input[type="checkbox"] {
        display: none;
    }

    .theme-toggle-sr {
        position:     absolute;
        overflow:     hidden;
        clip:         rect(0, 0, 0, 0);
        width:        1px;
        height:       1px;
        margin:       -1px;
        padding:      0;
        border-width: 0;
        white-space:  nowrap;
    }

    &.theme-toggle--reversed {
        .theme-toggle__classic {
            transform: scale(-1, 1);
        }
    }

    .theme-toggle__classic {
        width:  1.6rem;
        height: 1.6rem;
        color:  var(--theme-toggle-color);

        path {
            transition-timing-function: cubic-bezier(0, 0, 0.15, 1.25);
            transition-duration:        calc(var(--theme-toggle-classic-duration) * 0.8);
            transform-origin:           center;
        }

        g {
            path {
                transition-delay:    calc(var(--theme-toggle-classic-duration) * 0.2);
                transition-property: opacity, transform;
            }
        }

        :first-child {
            path {
                transition-property: transform, d;
            }
        }
    }
}

.theme-toggle--toggled:not(label).theme-toggle .theme-toggle__classic g path,
.theme-toggle input[type="checkbox"]:checked ~ .theme-toggle__classic g path {
    opacity:          0;
    transition-delay: 0s;
    transform:        scale(0.5) rotate(45deg);
}

.theme-toggle--toggled:not(label).theme-toggle .theme-toggle__classic :first-child path,
.theme-toggle input[type="checkbox"]:checked ~ .theme-toggle__classic :first-child path {
    transition-delay: calc(var(--theme-toggle-classic-duration) * 0.2);
    d:                path("M-12 5h30a1 1 0 0 0 9 13v24h-39Z");
}

@supports not (d: path("")) {
    .theme-toggle--toggled:not(label).theme-toggle .theme-toggle__classic :first-child path,
    .theme-toggle input[type="checkbox"]:checked ~ .theme-toggle__classic :first-child path {
        transform: translate3d(-12px, 10px, 0);
    }
}

@media (prefers-reduced-motion: reduce) {
    .theme-toggle:not(.theme-toggle--force-motion) * {
        transition: none !important;
    }
}
</style>
